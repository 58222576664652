import React from "react";
import Image from "./assets/image01.jpg";
import "./App.css";
import {
  MdOutlineAlternateEmail,
  MdOutlineWavingHand,
  MdAddPhotoAlternate,
} from "react-icons/md";
import {
  AiOutlineInstagram,
  AiFillLinkedin,
  AiFillMediumSquare,
  AiFillTwitterCircle,
  AiFillGithub,
} from "react-icons/ai";
import {
  SiUnsplash,
  SiShutterstock,
  SiDeviantart,
  SiQuora,
  SiDribbble,
} from "react-icons/si";
import { Fa500Px, FaBehanceSquare, FaPinterest } from "react-icons/fa";
import { CgPexels } from "react-icons/cg";

function App() {
  return (
    <div className="flex flex-col-reverse text-center lg:flex-row-reverse ">
      <div className="m-5">
        <p className="lg:text-6xl text-4xl font-bold mt-10">C. PRITHIVIRAJAN</p>
        <hr className="mt-5 ml-5 mr-5 mb-3" />
        <p className="sub-name mt-5 ml-5 mr-5 mb-3">
          Freelance Photographer and Graphic Designer
        </p>
        <div className="mt-5 flex flex-wrap gap-3 justify-center">
          <a
            target={`_blank`}
            href="mailto:prithivi@thephoenixdesigns.in"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md"
          >
            <MdOutlineAlternateEmail size={20} color="#d02434" />
            <span className="ml-2">Email</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.instagram.com/phoenixdesigns_cbe/"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md"
          >
            <AiOutlineInstagram size={20} color="#d02434" />
            <span className="ml-2">Phoenix Designs</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.linkedin.com/in/phoenixprithivi/"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md"
          >
            <AiFillLinkedin size={20} color="#d02434" />
            <span className="ml-2">LinkedIn</span>
          </a>
          <a
            target={`_blank`}
            href="https://medium.com/@phoenixprithivi/"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md medium"
          >
            <AiFillMediumSquare size={20} color="#d02434" />
            <span className="ml-2">Medium</span>
          </a>
          <a
            target={`_blank`}
            href="https://twitter.com/PhoenixPrithivi/"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <AiFillTwitterCircle size={20} color="#d02434" />
            <span className="ml-2">Twitter</span>
          </a>
          <a
            target={`_blank`}
            href="https://unsplash.com/@phoenixprithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <SiUnsplash size={20} color="#d02434" />
            <span className="ml-2">Unsplash</span>
          </a>
          <a
            target={`_blank`}
            href="https://500px.com/p/phoenixprithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <Fa500Px size={20} color="#d02434" />
            <span className="ml-2">500px</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.pexels.com/@phoenixprithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md insta"
          >
            <CgPexels size={20} color="#d02434" />
            <span className="ml-2">Pexels Profile</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.istockphoto.com/portfolio/phoenixprithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <MdAddPhotoAlternate size={20} color="#d02434" />
            <span className="ml-2">iStock</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.shutterstock.com/g/phoenixprithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  shutter"
          >
            <SiShutterstock size={20} color="#d02434" />
            <span className="ml-2">Shutterstock</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.deviantart.com/stockphoenix"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <SiDeviantart size={20} color="#d02434" />
            <span className="ml-2">Deviantart</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.behance.net/Phoenixprithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md behance"
          >
            <FaBehanceSquare size={20} color="#d02434" />
            <span className="ml-2">Behance</span>
          </a>
          <a
            target={`_blank`}
            href="https://in.pinterest.com/ThePhoenixDesigns"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md pint"
          >
            <FaPinterest size={20} color="#d02434" />
            <span className="ml-2">Pinterest</span>
          </a>
          <a
            target={`_blank`}
            href="https://dribbble.com/PhoenixPrithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <SiDribbble size={20} color="#d02434" />
            <span className="ml-2">Dribbble</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.quora.com/profile/Prithivi-Rajan-17"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <SiQuora size={20} color="#d02434" />
            <span className="ml-2">Quora</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.clubhouse.com/@phoenixprithivi"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md  email"
          >
            <MdOutlineWavingHand size={20} color="#d02434" />
            <span className="ml-2">ClubHouse</span>
          </a>
          <a
            target={`_blank`}
            href="https://www.instagram.com/studiophoenix_cbe/"
            className="flex justify-center items-center p-4 bg-gray-50 rounded-md insta"
          >
            <AiOutlineInstagram size={20} color="#d02434" />
            <span className="ml-2">Studio Phoenix</span>
          </a>
        </div>
        <div className="flex lg:flex-row flex-col justify-center items-center mt-14 ">
          Developed by &nbsp;
          <a
            target={`_blank`}
            href="https://www.linkedin.com/in/kathirvel-chandrasekaran/"
          >
            <span className="font-bold">Kathirvel Chandrasekaran</span>
          </a>
          &nbsp;
          <a
            target={`_blank`}
            href="https://github.com/KathirvelChandrasekaran"
          >
            <AiFillGithub size={20} color="#d02434" />
          </a>
        </div>
      </div>
      <img className="lg:h-screen mt-0" src={Image} alt="" />
    </div>
  );
}

export default App;
